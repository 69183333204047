const initialState = {
    isPreloading: false,
    isPreloaded: false,
    images: {},
    error: null,    
};

export const preloadReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PRELOAD_IMAGES_START':
            return { ...state, isPreloading: true };
        case 'PRELOAD_IMAGES_SUCCESS':
            return { ...state, isPreloading: false, isPreloaded: true, images: action.payload };
        case 'PRELOAD_IMAGES_FAILURE':
            return { ...state, isPreloading: false, error: action.payload };
        default:
            return state;
    }
};


