import { Column } from "../../components/common/Column"
import { Container } from "../../components/common/Container"
import { Section } from "../../components/common/Section"
import { Row } from "../../components/common/Row"
import { ContentBlock } from "../../components/common/ContentBlock"
import { Button } from "../../components/common/Button"

import './index.scss'

export const Immobilien = () => {
    return (
        <Section id={'immobilien'} background={'p_Steuererklarung-bg.png'}>
            <Container>
                <Row>
                    <Column flex={{ desktop: .6, mobile: 1 }}>
                        <ContentBlock color={'white'} className={'content'}>
                            <h2><strong>Werden Sie Eigentümer ohne Eigenkapital </strong></h2>

                            <p>
                                Unter Berücksichtigung des Erschwinglichkeitstests ermöglichen wir eine 100%ige Finanzierung. Unser Fokus auf nachhaltige Projekte sorgt nicht nur für umweltfreundliche Investitionen, sondern schafft auch eine solide Grundlage für Ihre langfristigen Investitionen.
                            </p>
                            <Row responsive>
                                <Column>
                                    <a href="https://immobilien.quick-credit.ch"><Button type={'primary'}>Mehr erfahren</Button></a>
                                </Column>
                                <Column flex={{ desktop: 0.05 }} />
                                <Column>
                                    <a href="https://immobilien.quick-credit.ch/antrag"><Button type={'secondary'}>Zur Immobilien Anfrage</Button></a>
                                </Column>
                            </Row>
                        </ContentBlock>
                    </Column>
                </Row>

            </Container>
        </Section>
    )
}