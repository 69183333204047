import { Column } from "../Column"
import { Row } from "../Row"
import { ContentBlock } from '../ContentBlock'
import { Image } from "../Image"
import { Space } from "../Space"
import {OnTop} from "../OnTop"
import {Button} from "../Button"
import { Link } from "../Link"
import { updateData } from "../../../redux/actions/dataActions"
import { Input } from "../Input"
import { useRef } from "react";
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons'
import './index.scss'
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"


export const SteuererklarungForm = () => {
    const ref = useRef(null)
    const [isMessageSending, setMessageSending] = useState(false)
    const [applicationSent, setApplicationSent] = useState(false)
    const navigate = useNavigate(); // Replace useHistory with useNavigate

    const handleFormSubmit = event => {
		event.preventDefault();
		setMessageSending(true);
		setApplicationSent(true)
		emailjs.sendForm('service_rrdmzkw', 'template_6atm2x9', ref.current, 'VkO7FJ4laHj-xh2Dl')
		  .then((result) => {
			console.log(result.text);
			setMessageSending(false);			
		  }, (error) => {
			console.log(error.text);
		  });
	  };

    return ( 
        <>  
        <Space size={5} desktop/>
        <ContentBlock id={'calculator'} background={'c_graytransparent'} color={'white'}>
            {!applicationSent && !isMessageSending && <>
            <Row justifyContent={'center'}><span className="form-headline">Angebot erhalten</span></Row>     
            <Space size={1} />       
            <form ref={ref} onSubmit={event => handleFormSubmit(event)}>	
              							
                <Row responsive>
                    <Input type={'text'} label='Vollständiger Name' id='steuererklarung-name' />
                </Row>
                
                <Row responsive>
                    <Input type='tel' label='Telefonnummer' id='steuererklarung-number' />
                    <Column flex={{ desktop: 0.025, mobile: 0 }} />
                    <Input type='email' label='E-Mail' id='steuererklarung-email' />
                </Row>

                <Row responsive>
                    <Input type={'date'} label='Geburtstag' id='steuererklarung-birthdate' />
                    
                </Row>

                <Row responsive>
                    <Input type={'text'} label='PLZ' id='steuererklarung-plz' />
                </Row>

                <Row responsive>
                    <Input type={'text'} label='ORT' id='steuererklarung-ort' />
                </Row>

                <Row responsive>										
                    <Input type={'select'} label='' id='steuererklarung-option' initial={'Einzelperson (CHF 49.-)'} options={[
                        'Familie (CHF 69.-)',
                    ]} />
				</Row>

                
                <Space size={1} />
                <Column>
                    <Button bType={'submit'} className="b"  type={'primary'}>Absenden</Button>
                </Column>
            </form>
            </>}
            {applicationSent &&
                                    <>
                                        {isMessageSending && <Row justifyContent={'center'}><LoadingOutlined style={{ fontSize: '82px' }} /></Row>}
                                        {!isMessageSending &&
                                            <>
                                                <Row justifyContent={'center'}>
                                                    <Column>
                                                        <h2 style={{ textAlign: 'center', textShadow: 'none' }}><strong>Vielen Dank</strong> für Ihre Nachricht.</h2>
                                                        <p style={{ textAlign: 'center' }}>Ihr Kundenberater wird sich in 24h bei Ihnen melden!</p>
                                                    </Column>


                                                </Row>
                                            </>
                                        }
                                    </>


                                }
            
        </ContentBlock>
        </>    
    
    )
}