// ThankYouPage.js
import React from 'react';
import { Section } from '../../components/common/Section';
import { Container } from '../../components/common/Container';

export const ApplicationFinish = () => (
  <Section>
    <Container>
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h2><strong>Vielen Dank für Ihre Anfrage.</strong></h2>
      <p>Ein Kreditexperte wird sich innerhalb der nächsten 24 Stunden bei Ihnen melden.</p>
    </div>
    </Container>
  </Section>
  
);


