
import { Input } from "../../components/common/Input";
import { Row } from "../../components/common/Row";
import { Column } from "../../components/common/Column";
import { Section } from "../../components/common/Section";
import { Space } from "../../components/common/Space";
import { Container } from "../../components/common/Container";
import { useSelector, useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons'
import emailjs from '@emailjs/browser';

import './index.scss'
import { updateData } from "../../redux/actions/dataActions";
import { Button } from "../../components/common/Button";
import { ContentBlock } from "../../components/common/ContentBlock";

export const ApplicationPage = () => {
	const ref = useRef(null)
	const [isMessageSending, setMessageSending] = useState(false)
	const navigate = useNavigate(); // Replace useHistory with useNavigate

	const dispatch = useDispatch()
	const [loanAmount, setLoanAmount] = useState(useSelector(state => state.data.loanAmount))
	const [applicationSent, setApplicationSent] = useState(false)
	const [loanTerm, setLoanTerm] = useState(72)

	const [terms, setTerms] = useState(false)

	const handleAmountChange = (event) => {
		setLoanAmount(event.target.value)
		dispatch(updateData({ loanAmount: loanAmount }))
	}

	const handleTermChange = (event) => {
		setLoanTerm(event.target.value)
		dispatch(updateData({ loanTerm: loanTerm }))
	}

	const handleFormSubmit = event => {
		event.preventDefault();
		setMessageSending(true);
		setApplicationSent(true)
		emailjs.sendForm('service_rrdmzkw', 'template_6atm2x9', ref.current, 'VkO7FJ4laHj-xh2Dl')
		  .then((result) => {
			console.log(result.text);
			setMessageSending(false);
			navigate('/antrag_erfolgreich'); // Use navigate instead of history.push
		  }, (error) => {
			console.log(error.text);
		  });
	  };

	const hiddenInputs = (
		<>
			<input name='loanAmount' value={useSelector(state => state.data['loanAmount']) || ''} readOnly style={{ display: 'none' }} />
			<input name='loanTerm' value={useSelector(state => state.data['loanTerm']) || ''} readOnly style={{ display: 'none' }} />
			<input name='ratenabsicherung' value={useSelector(state => state.data['ratenabsicherung']) || ''} readOnly style={{ display: 'none' }} />
			<input name='scoringauszug' value={useSelector(state => state.data['scoringauszug']) || ''} readOnly style={{ display: 'none' }} />
			<input name='zek' value={useSelector(state => state.data['zek']) || ''} readOnly style={{ display: 'none' }} />
			<input name='betreibungsauszug' value={useSelector(state => state.data['betreibungsauszug']) || ''} readOnly style={{ display: 'none' }} />
						
			<input name='application-name' id="application-name" value={useSelector(state => state.data['application-name']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-lastname' value={useSelector(state => state.data['application-lastname']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-birthdate' value={useSelector(state => state.data['application-birthdate']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-zivilstand' value={useSelector(state => state.data['application-zivilstand']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-number' value={useSelector(state => state.data['application-number']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-email' value={useSelector(state => state.data['application-email']) || ''} readOnly style={{ display: 'none' }} />
			<input name='arbeitsverhaltniss' value={useSelector(state => state.data['arbeitsverhaltniss']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-offene' value={useSelector(state => state.data['application-offene']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-kinder' value={useSelector(state => state.data['application-kinder']) || ''} readOnly style={{ display: 'none' }} />
			<input name='application-nachricht' value={useSelector(state => state.data['application-nachricht']) || ''} readOnly style={{ display: 'none' }} />			

		</>
	)	

	const calculateMonthlyPayment = rate => {
        rate = rate / 100 / 12
        let term = loanTerm / 12 * 12

        let payment = loanAmount.toString().replace("'", '') / (((Math.pow(1 + rate, term)) - 1) / (rate * (Math.pow(1 + rate, term))))

        return payment.toFixed(2)
    }

	return (
		<Section id={'applicationPage'}>
			<Container>

				<Column>
				{!applicationSent && !isMessageSending && <>
					{/* ApplicationPage page content */}
					<Row>
						<h2>Ihr <strong>Privatkredit</strong><span className="dot">.</span></h2>
					</Row>

					<p>Beantragen Sie hier Ihren gewünschten Privatkredit. Nach Prüfung Ihrer Kreditfähigkeit erhalten Sie online einen vorläufigen Kreditentscheid.</p>
					<p>Die Kreditvergabe ist verboten, wenn sie zur Überschuldung führt (Art. 3 UWG). Voraussetzung für die Kreditvergabe ist in jedem Falle eine erfolgreiche Kreditfähigkeitsprüfung.</p>
					<Space size={1} />
					
					<Space size={2} />

					<Row>
						<Column justifyContent={'center'} alignItems={'center'} flex={{ desktop: 2, mobile: 1.5 }}>

							<Input
								id='loanAmount'
								type='range'
								min={5000}
								max={300000}
								step={1000}
								label='Gewünschter Kreditbetrag'
								onChange={event => handleAmountChange(event)}
							/>

						</Column>

						<Column flex={{ desktop: 0.05, mobile: 0.2 }} />

						<Column justifyContent={'center'} alignItems={'center'} >
							<span className={'loanAmount'}>{loanAmount.toString().replace("'", '').replace(/\B(?=(\d{3})+(?!\d))/g, "'")}</span>
						</Column>
					</Row>

					<Space size={2} />

					<Row>
						<Column  flex={{ desktop: 2, mobile: 1.5 }}>

							<Input
								id='loanTerm'
								type='range'
								min={6}
								max={84}
								step={6}
								onChange={event => handleTermChange(event)}
								label='Laufzeit'
							/>

						</Column>

						<Column flex={{ desktop: 0.05, mobile: 0.2 }} />

						<Column justifyContent={'center'} alignItems={'center'} >
							<span style={{ border: 'none' }} className={'loanAmount'}>{loanTerm + ' Monate'}</span>
						</Column>
					</Row>

					<Space size={2} />

					<Column className={'payment-container'}>                    
						<span className="calculator-title payment">Beispiel für eine monatliche Zahlung:</span>
						<Space size={1} />
						<span><strong>CHF</strong> {calculateMonthlyPayment(4.4)}</span>                    
                	</Column>
					<Space size={5} />
					<Input type='switch' label={'Ratenabsicherung'} id='ratenabsicherung' />
					<Input type='switch' label={'Scoringauszug bestellen'} id='scoringauszug' />
					<Input type='switch' label={'ZEK - Auszug bestellen'} id='zek' />
					<Input type='switch' label={'Betreibungsauszug bestellen'} id='betreibungsauszug' />
					
					<Space size={5} />

					
						<>
							
							<ContentBlock>
								<form ref={ref} onSubmit={event => handleFormSubmit(event)}>
									{hiddenInputs}
									<Row responsive>
										<Input type={'text'} label='Vorname' id='application-name' />
										<Column flex={{ desktop: 0.025, mobile: 0.025 }} />
										<Input type={'text'} label='Nachname' id='application-lastname' />
									</Row>
									<Row responsive>
										<Input type={'date'} label='Geburtstag' id='application-birthdate' />
										<Column flex={{ desktop: 0.025, mobile: 0 }} />
										<Input type={'select'} label='Zivilstand' id='application-zivistand' options={[
											'Ledig',
											'Verheiratet',
											'Geschieden',
											'Getrennt',
											'Verwitwet',
											'Eingetragene Partnerschaft',
											'Gerichtlich aufgelöste Partnerschaft'
										]} />
									</Row>
									<Row responsive>
										<Input type='tel' label='Telefonnummer' id='application-number' />
										<Column flex={{ desktop: 0.025, mobile: 0 }} />
										<Input type='email' label='E-Mail' id='application-email' />
									</Row>
									<Space size={2} />
									<Input
										label='Arbeitsverhältniss'
										id='arbeitsverhaltniss'
										type='radio-block'
										columns={2}
										options={[
											'Angestellt',
											'Selbstständig erwerbstätig',
											'AHV/ IV/ EL - Rente'
										]}
									/>
									<Space size={2} />
									<Input
										label='Offene Finanzierrungen'
										id='application-offene'
										type='radio-block'
										columns={2}
										options={[
											'Kredit',
											'Leasing',
											'Hypotheke',
											'Bestehender Kredit',
										]}
									/>
									<Space size={2} />
									<Input
										label='Unterhaltspflichtige Kinder im gleichen Haushalt'
										id='application-kinder'
										type='radio-block'
										columns={2}
										options={[
											'Ja',
											'Nein',
										]}
									/>
									<Space size={2} />
									<Input type={'textarea'} label={'Nachricht'} id={'application-nachricht'} />
									<Space size={3} />
									<Row responsive>
										<Input type='checkbox' id='confirmaton1'>
											<p>
												Hiermit bestätige ich, dass ich die rechtlichen Hinweise vollständig
												gelesen habe und in allen Punkten mit ihnen einverstanden bin.
											</p>
										</Input>
										<Column flex={{ desktop: 0.1, mobile: 0 }} />
										<Input type='checkbox' id='confirmation2'>
											<p>
												Ich bin damit einverstanden, dass Quick Credit Schweiz meinen Kreditantrag
												bei Banken in der Schweiz bearbeitet und mir ein unverbindliches Angebot sendet.
											</p>
										</Input>
									</Row>
									<Space size={5} />

									<Column>
										<Button bType={'submit'} className="b"  type={'primary'}>Absenden</Button>
									</Column>

									<Space size={5} />

									<Row>
										<p style={{ textAlign: 'center' }}>
											Kreditdarstellung: Kredit von CHF 10'000. Effektive Jahreszinsen zwischen 4,5 % und 10,95 % über einen Zeitraum von 12 Monaten führen zu Gesamtzinsen zwischen CHF 318 und CHF 575. Laufzeit: 6-120 Monate; Maximaler jährlicher Zinssatz (einschließlich aller Kreditbearbeitungskosten) 10,95 %. Kreditgenehmigungen sind verboten, wenn sie zu einer Überschuldung des Verbrauchers führen. (Art. 3 LCD)
										</p>
									</Row>

								</form>
							</ContentBlock>
						</>
					
					</> }

					{applicationSent &&
									<>
										{isMessageSending && <Row justifyContent={'center'}><LoadingOutlined style={{ fontSize: '82px', color: 'blue' }} /></Row>}
										{!isMessageSending &&
											<>
												<Row justifyContent={'center'}>
													<Column>
														<h2 style={{ textAlign: 'center' }}><strong>Vielen Dank für Ihre Anfrage.</strong></h2>
														<p style={{ textAlign: 'center' }}>Ein Kreditexperte wird sich innerhalb der nächsten 24 Stunden bei Ihnen melden.</p>
													</Column>


												</Row>
											</>
										}
									</>


								}
				</Column>
			</Container>
		</Section>
	);
}