import { Button } from '../../common/Button'
import { Column } from '../../common/Column'
import { Row } from '../../common/Row'
import { ContentBlock } from '../../common/ContentBlock'
import { Image } from '../../common/Image'
import { useState } from 'react'

import './index.scss'
import { Space } from '../../common/Space'

export const QuickContact = () => {
    const [contactOpen, setContactOpen] = useState(false)

    return (       
        <div id='quickContact'>
            {!contactOpen && 
            
            <div className="contact-icon" onClick={() => setContactOpen(true)}>
                <Image name={'Chat.svg'} alt={'zins'}/>
            </div>
            }

            {contactOpen && 
            <ContentBlock className={'call-content'} background={'c_white'}>
                <button onClick={() => setContactOpen(false)} className='close-button'><Image width={'100%'} height={'100%'} name={'close-black.svg'} alt={'zins'}/></button>
                
                <Column alignItems={'center'}>
                    
                    <span className='contact-headline'>Gratis Beratung</span>
                    <span>Sie können uns auch einfach erreichen per:</span>

                </Column>
                <Space size={2} />
                <Row responsive>
                    <a href='https://wa.me/41764428925?text=Guten%20Tag,%20ich%20interessiere%20mich%20für%20einen%20Kredit.'>
                        <Button icon={'contact-whatsapp.svg'}  type={'primary'}>WhatsApp</Button>    
                    </a>    
                    
                    <a href='mailto: info@quick-credit.ch' className='center-button'>           
                        <Button icon={'contact-email.svg'}  type={'primary'}>E-Mail</Button>
                    </a> 
                    <a  href="tel: +41764428925">
                        <Button icon={'contact-telefon.svg'}  type={'primary'}>Telefon</Button>
                    </a>
                </Row>
            </ContentBlock>
            }

            
        </div> 

    
    )
}