import { Column } from "../Column"
import { Row } from "../Row"
import { ContentBlock } from '../ContentBlock'
import { Image } from "../Image"
import { Space } from "../Space"
import {OnTop} from "../OnTop"
import {Button} from "../Button"
import { Link } from "../Link"
import { updateData } from "../../../redux/actions/dataActions"

import './index.scss'
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

export const Calculator = () => {
    const [loanAmount, setLoanAmount] = useState(useSelector(state => state.data.loanAmount) || '')
    const [amountFocus, setAmountFocus] = useState(false)
    const dispatch = useDispatch()    
    
    
    const calculateMonthlyPayment = rate => {
        rate = rate / 100 / 12
        let term = 72 / 12 * 12

        let payment = loanAmount.toString().replace("'", '') / (((Math.pow(1 + rate, term)) - 1) / (rate * (Math.pow(1 + rate, term))))

        return payment.toFixed(2)
    }

    const handleAmountOnBlur = (event) => {
        let amount = event.target.value.toString().replace(`'`, '').replace(`'`, '')
        setAmountFocus(false)
        if (parseInt(amount) < 5000 || !parseInt(amount)) {
          setLoanAmount(5000)
          dispatch(updateData(5000)) 
        }
        else if (parseInt(amount) > 300000) {
          setLoanAmount(300000)  
          dispatch(updateData(300000))    
        } else {
            dispatch(updateData({loanAmount:parseInt(amount)}))
        }                 
    }

    const handleAmountFocus = event => {
        setAmountFocus(true)
        setLoanAmount('')        
    }

    return (        
        <ContentBlock id={'calculator'} background={'c_graytransparent'}>
            <Column>
                
                <Row className={'amount-container'}>
                    <Column flex={{desktop: 4, mobile: 10}}>
                        <Row justifyContent={'center'} className={'calc-content'}>                            
                            <span>Gewünschte Summe:</span>
                        </Row>
                        
                        <Space size={1} />
                        <Row justifyContent={'center'} className={'calc-content'}>
                            <span className="chf"><strong>CHF</strong></span>
                            <div style={{position:'relative', display:'flex', alignItems: 'center'}} >

                            
                            <input
                                id="amount-input"
                                type="text"
                                size={6}
                                value={loanAmount.toString().replace("'",'').replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
                                onFocus={handleAmountFocus}
                                className='xs-m-10-t text-center font-sm bold-2'      
                                onChange={event => {
                                    if (event.target.value.length < 9)
                                        setLoanAmount(event.target.value)
                                }}         
                                inputMode='numeric' // Ensures numeric keyboard on mobile              
                                onBlur={handleAmountOnBlur}
                            />
                            <Image name='calculator.svg' className={'calculator-icon'} height={'40px'} alt={'kalkulator'}/>
                            {!amountFocus && <Image className={'edit-icon'} name={'pen.svg'} height={'15px'} alt={'bearbeiten'}/>}                            
                            </div>
                        </Row>
                    
                    </Column>
                </Row>
                <Space size={1} />
                <Column className={'payment-container'} color={'white'}>                    
                    <span className="calculator-title payment">Beispiel für eine monatliche Zahlung:</span>
                    <Space size={1} />
                    <span><strong>CHF</strong> {calculateMonthlyPayment(4.4)}</span>                    
                </Column>
                

                
            </Column>
            
            <OnTop left={'50%'} bottom={'-38px'}>
                <Button icon={'application.svg'}  type={'primary'}>
                    <Link href={'/application'}>
                        Kreditantrag stellen
                    </Link>
                    
                </Button>
            </OnTop>
        </ContentBlock>
    
    )
}