import { Column } from "../../components/common/Column"
import { Container } from "../../components/common/Container"
import { Row } from "../../components/common/Row"
import { SteuererklarungForm } from "../../components/common/SteuererklarungForm"
import { Calculator } from "../../components/common/Calculator"
import { Section } from "../../components/common/Section"

import './index.scss'
import { ContentBlock } from "../../components/common/ContentBlock"
import { Space } from "../../components/common/Space"

export const SteuererklarungHeader = () => {
    return (
        <div id="header" className="steuer-header">
            <Section id={'immobilien'} background={'p_Steuererklarung-bg.png'} className={'steuererkalrung-section'}>
                <Container>
                    <Space size={10} desktop />
                    <Row responsive>
                        <Column color={'white'} alignItems='flex-start' justifyContent='center'>                        

                            <h1><b>Steuererklärung Service</b></h1>                                                
                            <span className="header-slogan">professionell ausfüllen lassen.</span>
                            <p>
                                Jedes Jahr die gleiche Frage: Wer macht meine Steuererklärung? Selber ausfüllen ist mühsam, 
                                ein Steuerberater teuer. Unsere Lösung: schnell, zuverlässig, kostengünstig.
                            </p>
                            
                            
                        </Column>
                        <Column alignItems='center' justifyContent='center'>                        
                            <SteuererklarungForm />                            
                        </Column>
                    </Row>
                </Container> 
            </Section>                                                          
        </div>
    )
}